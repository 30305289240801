import React from "react"
import Layout from "../components/layout"
import "../styles/forms.css"
import SEO from "../components/seo"

export default function BookNow() {
  return (
    <Layout title="Book">
      <SEO
        title="Book your session today."
        keyWords={[
          `Social Media Content Creator`,
          `Social Media Agency`,
          `Albuquerque`,
          `Albuquerque New Mexico`,
          `Product Photography`,
          `Rio Rancho, NM`,
          `Food Photography`,
          `Brand Content`,
          `ABQ Local`,
          `Comercial Photography`,
          `Soona Albuquerque`,
        ]}
      />
      <div id="form-wrapper">
        <form
          name="BookNow"
          method="POST"
          data-netlify="true"
          action="/success"
        >
          <input type="hidden" name="form-name" value="BookNow" />
          <p>
            <label>
              <input type="text" name="name:" placeholder="Name" required />
            </label>
          </p>
          <p>
            <label>
              <input type="email" name="email:" placeholder="Email" required />
            </label>
          </p>
          <p>
            <label>
              <input type="phone" name="phone:" placeholder="Phone" required />
            </label>
          </p>
          <p>
            <label id="selection">
              <select name="Session Type:" class="styled-select" required>
                <option value="" disabled selected>
                  Choose Session Type
                </option>
                <option value="On-location">On-location</option>
                <option value="Local Pick-Up">Creative</option>
                <option value="Ship">Still Life</option>
                <option value="Ship">Mix & Match</option>
              </select>
            </label>
            <p />
            <p>
              You can ship your products if you aren't local to my location in
              Albuquerque, NM USA
            </p>
            <label id="selection">
              <select name="Location" class="styled-select">
                <option value="" disabled selected>
                  Local or Out-of-State:
                </option>
                <option value="On-location">
                  Local (Albuquerque & Surounding Areas)
                </option>
                <option value="Local Pick-Up">I'll ship my product.</option>
              </select>
            </label>
          </p>
          <p class="button-wrapper">
            <button type="submit">Send</button>
          </p>
        </form>
      </div>
      )
    </Layout>
  )
}
